import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { Services } from "./services/Services";
import {
  setAllLanguages,
  setSelectedLanguage,
  setLockedCategories,
  setPinCode,
  setChannels,
  setRadioStations,
  setLoader,
  setLanguageModalVisible,
} from "./redux/actions";

import Login from "./auth/Login";
import Register from "./auth/Register";
import HomeMobile from "./views/Unauthorized/Mobile/Home/Home";
import LoginMobile from "./views/Unauthorized/Mobile/LoginMobile/LoginMobile";
import RadioMobile from "./views/Unauthorized/Mobile/Radio/Radio";
import NewsMobile from "./views/Unauthorized/Mobile/News/News";
import NewsItem from "./views/Unauthorized/Mobile/News/NewsItem";
import More from "./views/Unauthorized/Mobile/More/More";

// import hooks
import { usePageViews } from "./hooks/usePageViews";

// Home
import Home from "./views/HomeScreen/Home";
// Channels
import TVchannels from "./views/Channels/TVchannels/TVchannels";
import Radio from "./views/Channels/Radio/Radio";
// News
import News from "./views/News/News";
import Spinner from "./Spinner";
import Player from "./components/VideoPlayer/Player/Player";
import InfoModal from "./components/InfoModal/InfoModal";
import PINcode from "./components/PINcode/PINcode";
import LeftSliderPIN from "./components/VideoPlayer/LeftSlider/LeftSliderPIN";
import LoginModal from "./components/Unauthorized/Web/LoginModal/LoginModal";
import ChooseLang from "./components/Unauthorized/Web/ChooseLang/ChooseLang";
// import helpers
import { isMobile } from "./helpers/deviceDetect";
import { demo } from "./reskin/reskin";

const UnauthorizedRoot = ({
  info_modal,
  pincode,
  left_slide_pin,
  loader,
  player,
  allLanguages,
  lang,
  setAllLanguages,
  setSelectedLanguage,
  setLockedCategories,
  setPinCode,
  setChannels,
  setRadioStations,
  setLoader,
  locked,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    Services.allLanguages().then((res) => {
      if (isMobile) {
        if (localStorage.locale && res.data.languages) {
          const languages = res.data.languages;
          const language = languages.filter(
            (item) => item.id === JSON.parse(localStorage.locale)
          );
          setAllLanguages(languages);
          setSelectedLanguage(language);
        } else if (res.data.languages) {
          const languages = res.data.languages;
          setAllLanguages(languages);
          setSelectedLanguage(languages[0]);
        }
      }
    });

    // Services.getDemoLanguage().then((res) => {
    //   console.log("localStorage.locale:", localStorage.locale);
    //   console.log("res.data.language:", res.data.language);
    //   // setSelectedLanguage(res.data.language);
    // });

    let param = { filter: "", category: "", sort: "", favorite: "", epgs: 1 };

    Services.getDemoChannels(param).then((res) => {
      setChannels(res.data);
      setLoader(false);
    });

    Services.getDemoRadios().then((res) => {
      setRadioStations(res.data.radios);
    });
    // eslint-disable-next-line
  }, [
    setLockedCategories,
    setPinCode,
    setSelectedLanguage,
    setChannels,
    setLoader,
    locked,
  ]);

  const modalVisible = useSelector((state) => state.modalVisible);
  const langModalVisible = useSelector((state) => state.langModalVisible);

  useEffect(() => {
    if (localStorage.locale) {
      dispatch(setLanguageModalVisible(false));
    } else {
      dispatch(setLanguageModalVisible(true));
    }
    // eslint-disable-next-line
  }, []);

  // google analytics page view
  usePageViews();

  return (
    <>
      {demo ? (
        isMobile ? (
          <Switch>
            <Route path="/login" exact component={LoginMobile} />
            <Route path="/tv" exact component={HomeMobile} />
            <Route path="/radio" component={RadioMobile} />
            <Route path="/news" exact component={NewsMobile} />
            <Route path="/news/item" exact component={NewsItem} />
            <Route path="/more" exact component={More} />
            <Redirect to="/tv" />
          </Switch>
        ) : (
          <>
            {loader && <Spinner lang={lang} />}
            {player && <Player />}
            {info_modal && <InfoModal />}
            {pincode === "opened" && <PINcode />}
            {left_slide_pin === "opened" && <LeftSliderPIN />}
            {modalVisible && <LoginModal />}
            {langModalVisible && <ChooseLang />}
            <Switch>
              <Route path="/channels/epgs" component={TVchannels} />
              <Route exact path="/tv" component={Home} />
              <Route path="/login" exact component={Login} />
              <Route path="/signup" exact component={Register} />
              <Route path="/channels/radios" component={Radio} />
              <Route path="/news" component={News} />
              <Redirect to="/channels/epgs" />
            </Switch>
          </>
        )
      ) : isMobile ? (
        <Switch>
          <Route path="/" exact component={LoginMobile} />
          <Redirect to="/" />
        </Switch>
      ) : (
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/signup" exact component={Register} />
          <Redirect to="/login" />
        </Switch>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loader: state.loader,
  allLanguages: state.allLanguages,
  lang: state.selectedLanguage,
  player: state.playerVisible,
  info_modal: state.info_modal,
  pincode: state.pinCodeModal,
  left_slide_pin: state.leftSliderPin,
});

export default connect(mapStateToProps, {
  setAllLanguages,
  setSelectedLanguage,
  setLockedCategories,
  setPinCode,
  setChannels,
  setRadioStations,
  setLoader,
})(UnauthorizedRoot);
