// import dependencies
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga';

// usePageViews hook
export const usePageViews = () => {
  let location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);
};