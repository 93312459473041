// import dependencies
import React from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter } from "react-router-dom";

// import root
import UnauthorizedRoot from './UnauthorizedRoot';
import Root from './Root';

// import helpers
import { utility } from "./helpers/Utility";

// Initialize Google Analytics
ReactGA.initialize(utility.gaTrackingCode);

const App = () => <BrowserRouter>
  {localStorage.jwtToken ? <Root /> : <UnauthorizedRoot />}
</BrowserRouter>

export default App;
